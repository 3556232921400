<template>
  <div v-loading="spinner">
    <el-row :gutter="20" class="mt20 mb10" v-if="this.stats">
      <el-col :span="6" v-if="minCountProduct !== null">
        <el-row :gutter="20">
          <el-carousel height="150px" :interval="3000" type="card">
            <el-carousel-item v-for="(product, index) in minCountProduct" :key="index">
              <router-link :to="{ name: 'updateProduct', params: { 'product_id': product.productId }}">
                <el-card>
                  <h3 class="small">{{ product.name_mon }}</h3>
                  <el-row>
                    <el-col :span="8">
                      <el-image
                        style="width: 50px; height: 50px"
                        :src="Array.isArray(product.image) ? product.image[0] : product.image"
                        fit="fill">
                      </el-image>
                    </el-col>
                    <el-col :span="16">
                      <h4>Үлдэгдэл: {{product.count}}</h4>
                    </el-col>
                  </el-row>
                </el-card>
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </el-row>
      </el-col>
      <el-col :span="6">
        <router-link to="/order">
          <el-card shadow="hover">
            <h3 class="text-center">Захиалгын тоо</h3>
            <el-row :gutter="20" type="flex" align="center" justify="center">
              <el-col :span="8" class="text-center">
                <font-awesome-icon icon="fa-solid fa-truck" size="5x" class="icon-order"/>
              </el-col>
              <el-col :span="16" class="text-justify-center">
                <el-row>
                  <el-row class="panel-item1">Амжилттай ({{stats.completed_order}})</el-row>
                  <el-row class="mt10 panel-item2">Хүлээгдэж буй ({{stats.pending_order}})</el-row>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
        </router-link>
      </el-col>
      <el-col :span="6">
        <router-link to="/order">
          <el-card shadow="hover">
            <h3 class="text-center">Цуцлалт</h3>
            <el-row :gutter="20" type="flex" align="center" justify="center">
              <el-col :span="8" class="text-center">
                <font-awesome-icon icon="fa-solid fa-handshake-simple-slash" size="5x" class="icon-cancel"/>
              </el-col>
              <el-col :span="16" class="text-justify-center">
                <el-row :gutter="20">
                  <el-row class="panel-item1">Цуцлалт/ш ({{stats.cancel_order}})</el-row>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
        </router-link>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover">
          <h3 class="text-center">Үнэлгээ</h3>
          <el-row :gutter="20" type="flex" align="center" justify="center">
            <el-col :span="8" class="text-center">
              <font-awesome-icon icon="fa-solid fa-star-half-stroke" size="5x" class="icon-review"/>
            </el-col>
            <el-col :span="16" class="text-justify-center">
              <el-row  class="panel-item2">Дундаж үнэлгээ ({{stats.review_count}})</el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-row :gutter="20">
        <el-col :span="24">
          <el-row :gutter="20" type="flex" align="bottom">
            <el-col :span="8">
              <el-date-picker
                v-model="dateRange"
                default-value="2021-06-01"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="To"
                start-placeholder="Эхлэх огноо"
                end-placeholder="Дуусах огноо"
                :picker-options="pickerOptions"
                @change="getStats(supplierId)">
              </el-date-picker>
            </el-col>
            <el-col :span="16" v-if="topProduct" :lg="{span: 20, offset: 4}" class="hiddenColumn">
              <router-link :to="{ name: 'updateProduct', params: { 'product_id': topProduct.productId }}">
                <el-card>
                  <el-row :gutter="20" type="flex" align="middle" justify="center">
                      <el-tag class="mr20">Топ бүтээгдэхүүн <i class="el-icon-right"></i></el-tag>
                      <el-row :gutter="20">
                        <el-col :span="4">
                          <el-image
                            class="image"
                            :src="Array.isArray(topProduct.image) ? topProduct.image[0] : topProduct.image"
                            fit="fit">
                          </el-image>
                        </el-col>
                        <el-col :span="20">
                          <el-row :gutter="20" type="flex" align="middle">
                            <el-row class="panel-item2 pl30">{{topProduct.name_mon}}</el-row>
                            <h4 class="ml10">Үнэ: {{generateCurrency(topProduct.price)}}</h4>
                          </el-row>
                        </el-col>
                      </el-row>
                  </el-row>
                </el-card>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="text-right mt30">
        <el-col :span="24">
          <sales-chart :outletIds.sync="supplierId" :dateRanges.sync="dateRange"></sales-chart>
        </el-col>
      </el-row>
      </el-col>
      <el-col :span="6">
        <el-row v-if="clientInfo.length > 0">
          <h2 class="text-center">МЭДЭЭ МЭДЭЭЛЭЛ</h2>
           <el-carousel height="490px" :autoplay="true" :interval="4000" >
            <el-carousel-item v-for="(post, index) in clientInfo" :key="index">
              <a :href="post.url" target="_blank">
                <el-image
                  style="width: auto; height: auto"
                  :src="post.feature_image"
                  fit="cover">
                </el-image>
                <h3 class="text-center">{{post.title}}</h3>
                <span v-html="post.html"></span>
              </a>
            </el-carousel-item>
          </el-carousel>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SalesChart from '../../components/SalesChartHome'
import services from '../../helpers/services'
import { getSupplierId } from '@/utils/auth.js'
import { handlers } from '../../helpers/custom'
import helper from '@/helpers/helper.js'
export default {
  name: 'homePage',
  components: {
    SalesChart
  },
  data () {
    return {
      clientInfo: [],
      minCountProduct: null,
      topProduct: null,
      productData: null,
      supplierId: null,
      spinner: false,
      stats: null,
      choosedOutletIds: [],
      choosedOutletNames: [],
      defaultEndDate: new Date(),
      defaultStartDate: new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7),
      dateRange: null,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '7 хоногоор',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '12 сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  async created () {
    this.dateRange = [this.defaultStartDate, this.defaultEndDate]
    this.getAllReports()
    this.supplierId = await getSupplierId()
    this.getStats(await getSupplierId())
    this.getProductsBySupplierId()
    this.getClientInfo()
  },
  methods: {
    generateCurrency (price) {
      return helper.generateCurrency(parseFloat(price))
    },
    getProductsBySupplierId () {
      const body = {
        search_text: '',
        field: '',
        sort: '',
        status: '',
        size: 20,
        from: 0,
        brand_id: ['b61605bf-a02b-4d28-a138-d5fbd6e2aca0', '15565f22-351f-4887-b4c5-eedf47ebfac7']
      }
      services.getProductsBySupplierId(body).then(response => {
        if (response.status === 'success' && response.data) {
          this.productData = response.data
        }
      })
    },

    getAllReports () {
      if (this.dateRange.length === 0) {
        this.$notify({
          title: 'Алдаа',
          message: 'Өдрийн интервалийг сонгоно уу',
          type: 'error',
          position: 'top-right'
        })
        return false
      }
    },

    getClientInfo () {
      services.getClientInfo().then(response => {
        if (response.posts.length > 0) {
          const tempData = JSON.parse(JSON.stringify(response.posts))
          tempData.forEach(element => {
            if (element.feature_image !== null) {
              this.clientInfo.push(element)
            }
          })
        }
      })
    },

    getStats () {
      this.spinner = true
      const query = '?start_date=' + helper.formatDate(this.dateRange[0], 'YYYY-MM-DD 00:00:00') + '&end_date=' + helper.formatDate(this.dateRange[1], 'YYYY-MM-DD 00:00:00')
      services.getStats(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.stats = response.data
          this.topProduct = response.data.popular_products ? response.data.popular_products[0] : []
          this.minCountProduct = Object.prototype.hasOwnProperty.call(response.data, 'min_count_products') && response.data.min_count_products.length > 0 ? response.data.min_count_products : null
          localStorage.setItem('brandCount', response.data.brand_count)
          if (response.data.brand_count === 0) {
            handlers.alertReporter('Уучлаарай та брэндээ үүсгэнэ үү', 'warning')
            this.$router.push({ path: '/create?activeStep=1', query: { supplier_id: this.supplierId.length > 0 ? this.supplierId[0] : '' } })
          }
          this.spinner = false
        } else {
          this.spinner = false
        }
      })
    }
  }
}
</script>
<style scoped>
  h3 {
    color: #606266;
  }
  .panel-item1 {
    font-size: 16px;
    font-weight: bold;
  }
  .panel-item2 {
    color: rgba(0,0,0,.45);
    font-size: 16px;
    font-weight: bold;
  }
  .icon-brand {
    color: #00b159;
  }
  .icon-product {
    color: #36a3f7;
  }
  .icon-cancel {
    color: #d11141
  }
  .icon-warehouse {
    color: #E6A23C;
  }
  .icon-review {
    color: #909399;
  }
  .icon-sale {
    color: #409EFF;
  }
  .icon-order {
    color: #34ebc9;
  }
  .image {
    width: 60px;
    height: 60px;
    display: block;
  }
  .el-carousel__item {
    overflow-y: scroll;
  }
  ::-webkit-scrollbar-track {
    background: inherit;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #7cbb00;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  @media only screen and (max-width: 1370px) {
  .hiddenColumn {
    display: none;
  }
}
  /* .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
</style>
